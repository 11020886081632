import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";

const buildTarget = process.env.REACT_APP_BUILD_TARGET || "lk";

function importBuildTarget() {
  return import(`./entrypoints/${buildTarget}`);
}

importBuildTarget().then(({ default: Environment }) => {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(<Environment />);
});
